import ios from 'assets/icons/ios.svg'
import android from 'assets/icons/android.svg'

export const CardHeadTitle = ({ card }) => {

  const lowerPlatformName = card.type.toLowerCase()

  const platformsIcons = {
    "ios": <img style={{marginRight: '15px'}} src={ ios } alt='ios platform'/>,
    'android': <img style={{marginRight: '15px'}} src={ android } alt='ios platform'/>,
    'aab': <img style={{marginRight: '15px'}} src={ android } alt='ios platform'/>,
    'amazon': <img style={{marginRight: '15px'}} src={ android } alt='ios platform'/>,
  }

  return (
    <div className='card-body card-head-title' style={{justifyContent:'space-between'}}>
      <div style={{display: 'flex', alignItems:'flex-start'}}>
        { platformsIcons[lowerPlatformName] }
        <div>
          <p className = 'product-title' style = {{ marginBottom:'-5px' }} > { card.name } <span className='product-description'>{ card.version } ({ card.bundle_version || 'No bundle version' })</span> </p>
          <p className='product-description' style = {{ marginBottom:'-5px' }}>{ card.bundle_id }</p>
          { card.space_name && <p className='product-description'>{ card.space_name }</p> }
        </div>
      </div>
      <div class="build-status">{ card.status }</div>
  </div>
  )
}