import { Link, useParams } from 'react-router-dom'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Select from 'react-select'
import { createStructuredSelector } from 'reselect'
import { selectors, actions } from 'store'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from 'hooks'
import Modal from 'components/ui/Modal'
import { Loader } from 'components/ui/Loader'
import { CardHeadTitle } from 'components/ui/CardHeadTitle'
import { CardWrapper } from 'components/ui/CardWrapper'
import { CardDownloadBtn } from 'components/ui/CardDownloadBtn'
import { CardQRBtn } from 'components/ui/CardQRBtn'

const rootSelector = createStructuredSelector({
	isInitialized: selectors.session.isInitialized,
	cards: selectors.cards.items,
	loading: selectors.cards.loading,
	types: selectors.session.types,
	name: selectors.auth.name,
})
 
export const SpacePage = () => {
	const dispatch = useDispatch()
	const { isInitialized, cards, loading, types } = useSelector(rootSelector)
	const [show, setShow] = useState(false)
	const [curCard, setCurCard] = useState()
	const { query, set } = useQuery()
	const selectedTypes = useMemo(
		() => JSON.parse(query.get('type') || '[]'),
		[query]
	)
 
	const { spaces } = useParams()
	const onSelectType = useCallback(
		(type) => {
			const arrOfTypes = []
			if (type !== null) {
				type.forEach((item) => {
					arrOfTypes.push(item.value.toLowerCase())
				})
			}
			localStorage.setItem('types', JSON.stringify(arrOfTypes))
			set('type', JSON.stringify(arrOfTypes))
		},
		[set]
	)

	const setCurCardHandler = (e, card) => {
		setCurCard(card)
		e.preventDefault()
		e.stopPropagation()
		setShow((show) => !show)
	}

	useEffect(() => {
		if (isInitialized) {
			dispatch(
				actions.cards.getCards.init({
					spaces,
					type:
						JSON.parse(localStorage.getItem('types')) ||
						selectedTypes,
				})
			)
		}
	}, [isInitialized, spaces, selectedTypes, dispatch])

	return (
		<div className='spacePage-container' style={{ width: '100%' }}>
			<div
				className='product-nav-wrapper row'
				style={{ marginBottom: ' 1.437rem' }}
			>
				<div className='col-lg-4 col-md-5 upload-btn'>
					<Link to='/upload'>
						<button
							type='button'
							className='btn btn-outline-danger btn-icon-text'
						>
							<i className='ti-upload btn-icon-prepend'></i>
							Upload file
						</button>
					</Link>
				</div>
				<div
					className='col-lg-8 col-md-7 product-filter-options'
					style={{ justifyContent: 'flex-end' }}
				>
					<Select
						isMulti={true}
						placeholder='Types'
						defaultValue={
							localStorage.getItem('types')
								? JSON.parse(localStorage.getItem('types')).map(
										(item) => {
											return { value: item, label: item }
										}
								  )
								: []
						}
						options={Object.values(types).map((type) => {
							return { value: type, label: type }
						})}
						onChange={onSelectType}
					/>
				</div>
			</div>
			<div
				className='row product-item-wrapper'
				style={{ display: 'flex', justifyContent: 'flex-start' }}
			>
				<Modal
					currentCard={curCard}
					show={show}
					onClose={() => setShow((show) => !show)}
				/>

				{loading ? <Loader/> : 
					cards.map((card) => {
						if (selectedTypes.includes(card.type.toLowerCase()) || selectedTypes.length === 0) {
						return (
						<div
							className='col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12 product-item stretch-card'
							key={card.id}
						> 
							<div
								className='card'
								style={{ borderRadius: '10px' }}
							>
								<Link 
									to={
										spaces !== undefined
											? `/${spaces}/${card.id}`
											: `/${card.spaces_id}/${card.id}`
									}
									style={{
										color: '#b1b1b5',
										textDecoration: 'none',
									}}
								>
									<CardHeadTitle card={card}/>
									<CardWrapper 
										image={card.image}
										created_at={card.created_at}
										buttons={
											<div className='btn-container'>
												<CardDownloadBtn downloadHref={card.download}/>
												<CardQRBtn onclickHandler={(e) => setCurCardHandler(e, card)}/>
											</div>
										}
									/>
								</Link>
							</div>
						</div>)
						} else return ''
					}
				)}
			</div>
		</div>
	)
}
