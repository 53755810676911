import React, { useEffect, useRef, useState } from 'react'
import { Spin } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { actions, selectors } from '../../store'
import { openNotification } from '../../store/sagas/utils'
import { Form } from 'react-bootstrap'
import GoBack from './GoBack'

const rootSelector = createStructuredSelector({
	loading: selectors.cards.loading,
	types: selectors.session.types,
	spaces: selectors.session.spaces,
	isInitialized: selectors.session.isInitialized,
})

export const FileUpload = ({ selectedTypes }) => {
	const [selectedFile, setSelectedFile] = useState()
	const [isFileUploaded, setIsFileUploaded] = useState(true)
	const [typeSelectValue, setTypeSelectValue] = useState('')
	const [spaceSelectValue, setSpaceSelectValue] = useState('')
	const [fileNameSelect, setFileNameSelect] = useState('Upload your file')
	const [statusSelectValue, setStatusSelectValue] = useState('Demo')
	const [percentageUploads, setPercentageUploads] = useState(0)
	const [comment, setComment] = useState('')

	const typeRef = useRef()
	const spaceRef = useRef()
	const statuseRef = useRef()
	const textAreaRef = useRef()

	const { loading, types, spaces, isInitialized } = useSelector(rootSelector)

	const dispatch = useDispatch()

	const responseTextField = document.getElementById('response-text')

	const changeHandler = (e) => {
		setFileNameSelect( <span style={{color: '#aaa7a7'}}>{e.target.files[0].name}</span> )
		setSelectedFile(e.target.files[0])
	}

	const typesSelectHandler = (e) => setTypeSelectValue(e.target.value)
	const spacesSelectHandler = (e) => setSpaceSelectValue(e.target.value)
	const statusSelectHandler = (e) => setStatusSelectValue(e.target.value)
	const commentHandler = (e) => {
		e.preventDefault()
		textAreaRef.current.style.height='max-content'
	}

	const onBlurCommentHandler = (e) => {
		e.preventDefault()
		const currentComment = linkParcer(e.target.outerText)
		textAreaRef.current.innerHTML=currentComment
		setComment(currentComment)
	}

	const linkParcer = (string) => {
		let urlRegex = /(https?:\/\/[^\s]+)/g;
		let newStr = string.replaceAll('<', '&lt;').replaceAll('>', '&gt;');
		return newStr.replace(urlRegex, function(url){
			let link = `<a href=${url} onmouseover='this.style.textDecoration="underline"' contenteditable="false" onclick='e.stopPropagation()' target='__blank' onmouseleave='this.style.textDecoration="none"'>${url}</a> `
			return link
		})
	}

	const handleSubmission = (e) => {
		e.preventDefault()

		if (!localStorage.getItem('token')) {
			dispatch(actions.auth.logout())
		}
		responseTextField.innerHTML = ''

		localStorage.setItem('type', typeSelectValue)
		localStorage.setItem('space', spaceSelectValue)

		setIsFileUploaded(false)
		const formData = new FormData()

		formData.append('ipa', selectedFile)
		formData.append('type', typeSelectValue)
		formData.append('space_id', spaceSelectValue) 
		formData.append('status', statusSelectValue) 
		formData.append('comment', comment)

		let xhr = new XMLHttpRequest();
		xhr.upload.onprogress = (e) => setPercentageUploads(Math.ceil(e.loaded * 100 / e.total))

		xhr.onload = function (){
			let result = xhr.response
			setIsFileUploaded(true)

			if (result.status !== 200) {
				openNotification(
					'error',
					result.error.ipa ||
					result.error.type ||
					result.error.token ||
					'Incorrect file',
					'Error'
				)
				responseTextField.style.color = '#ff4747'
				responseTextField.innerHTML = `
					<p style="font-size: 20px; margin-bottom: 20px">${ result.error.ipa || result.error.token || 'Incorrect file.' }</p>
					<a style="font-size: 20px; border: 1px solid #248afd; padding: 5px 10px; text-decoration: none"
						href="${ window.location.protocol}//${window.location.host}/${result.data.space_id}/${ result.data.app_id}
					">Go to file</a>  
				`
			}

			if (result.status === 200) {
				openNotification('success', 'File uploaded!', 'Success')
				responseTextField.style.color = '#82dd55'
				responseTextField.innerHTML = `
					<p style="font-size: 20px; margin-bottom: 20px">File uploaded!</p>
					<a style="font-size: 20px; border: 1px solid #248afd; padding: 5px 10px; text-decoration: none"
					 	href="${window.location.protocol}//${window.location.host}/${result.data.app.spaces_id}/${result.data.app.id}
					">Go to file</a>
				`
			}
		}

		xhr.open( 'POST', 'https://ipa3.testdlab.com/api/v1/upload/file' )
		xhr.responseType = 'json'
		xhr.setRequestHeader( 'Authorization', `Bearer ${localStorage.getItem('token')}`)
		xhr.send(formData)
	}


	useEffect(() => {
		if (!localStorage.getItem('token')) {
			dispatch(actions.auth.logout())
		}

		if (isInitialized) {
			const currType = typeRef.current.options[0].value
			let currSpace = spaceRef.current.options[0].value

			setSpaceSelectValue(currSpace)
			setTypeSelectValue(currType)
		}

		if (localStorage.getItem('space') && localStorage.getItem('type')) {
			typeRef.current.value = localStorage.getItem('type')
			spaceRef.current.value = localStorage.getItem('space')
			setSpaceSelectValue( localStorage.getItem('space') )
			setTypeSelectValue( localStorage.getItem('type') )
		}
	}, [setSpaceSelectValue, setTypeSelectValue, isInitialized, dispatch])

	return (
		<>
			<GoBack />
			{ isFileUploaded === false ? <><Spin /> <span className='percentage-upload'>{percentageUploads}%</span></> : '' }
			{ loading ? ( <Spin />) : (
				<div className='row'>
					<div className='col-md-6 grid-margin stretch-card'>
						<div className='card'>
							<div className='card-body'>
								<form
									method='POST'
									encType='multipart/form-data'
									onSubmit={ handleSubmission }
								>
									<Form.Group>
										<label style={{ color: '#b1b1b5' }}>
											File upload
										</label>
										<div className='custom-file'>
											<Form.Control
												type='file'
												className='form-control visibility-hidden'
												required='required'
												id='customFileLang'
												lang='es'
												onChange={ changeHandler }
											/>
											<label
												className='custom-file-label'
												htmlFor='customFileLang'
											>
												{ fileNameSelect }
											</label>
										</div>
									</Form.Group>

									<Form.Group>
										<label
											htmlFor='exampleFormControlSelect1'
											style={{ color: '#b1b1b5' }}
										>
											Space select
										</label>
										<select
											ref={ spaceRef }
											onChange={ spacesSelectHandler }
											className='form-control form-control-lg'
											id='exampleFormControlSelect1 spaces-select'
										>
											{spaces.map((space) => (
												<option
													key={space.id}
													value={space.id}
													{...space}
												>
													{space.name}
												</option>
											))}
										</select>
									</Form.Group>

									<Form.Group>
										<label
											htmlFor='exampleFormControlSelect1'
											style={{ color: '#b1b1b5' }}
										>
											Type select
										</label>
										<select
											ref={typeRef}
											onChange={typesSelectHandler}
											className='form-control form-control-lg'
											id='exampleFormControlSelect1 types-select'
										>
											{Object.values(types).map(
												(type, index) => (
													<option
														key={index}
														value={type}
													>
														{type}
													</option>
												)
											)}
										</select>
									</Form.Group>

									{/* ------------------ */}
									<Form.Group>
										<label
											htmlFor='exampleFormControlSelect1'
											style={{ color: '#b1b1b5' }}
										>
											Status select
										</label>
										<select
											ref={statuseRef}
											onChange={statusSelectHandler}
											className='form-control form-control-lg'
											id='exampleFormControlSelect1 status-select'
										>
											{['Demo', 'Test', 'Prod'].map(
												(statuse, index) => ( <option key={index} value={statuse}> {statuse} </option> )
											)}
										</select>
									</Form.Group>
									{/* ------------------- */}

									<Form.Group>
										<label
											htmlFor='exampleTextarea1'
											style={{ color: '#b1b1b5' }}
										>
											Comment
										</label>
										<pre
											ref={textAreaRef}
											className='form-control'
											id='exampleTextarea1 textarea-field'
											rows='6'
											contentEditable='true'
											onInput={commentHandler}
											onBlur={onBlurCommentHandler}
											style={{
												fontFamily: 'inherit',
												whiteSpace: 'pre-wrap'
											}}
										></pre>
									</Form.Group>
									<div
										id='response-text'
										style={{
											fontSize: 24,
											marginBottom: 30,
										}}
									/>
									<button
										type='submit'
										className='btn btn-primary mr-2'
									>
										Upload
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}
