import React from 'react'
import 'App.css'

const Modal = (props) => {
	if (!props.showCardModal) return null

	return (
		<div className='modal' onClick={props.onClose}>
			<div className='modal-content' onClick={(e) => e.stopPropagation()}>
				<div className='modal-header'>
					<div>
						<h4 className='modal-title'>
							{props.currentCard.name}
						</h4>
						<h4 className='modal-title'>
							{props.currentCard.bundle_id}
						</h4>
					</div>
					<div className='modal-subtitle'>
						<h4 className='modal-title'>
							<span className="card-modal-text">Version: </span> 
							{props.currentCard.version}
						</h4>
						<h4 className='modal-title'>
							<span className="card-modal-text">Build id: </span>
							{props.currentCard.build_id}
						</h4>
					</div>
				</div>
				<div
					className='modal-body'
					style={{
						display: 'flex',
						flexDirection: 'column',
					}}
				>
					<h4 className='modal-title'>
						<span className="card-modal-text">Created_at: </span>
						{props.currentCard.created_at}
					</h4>
					<h4 className='modal-title'>
						<span className="card-modal-text">Updated at: </span>
						{props.currentCard.updated_at}
					</h4>
					<h4 className='modal-title'>
						<span className="card-modal-text">Minimum of version: </span>
						{props.currentCard.minimum_os_version}
					</h4>
					<h4 className='modal-title'>
						<span className="card-modal-text">Type: </span>
						{props.currentCard.type}
					</h4>
					<h4 className='modal-title'>
						<span className="card-modal-text">Space id: </span>
						{props.currentCard.space_id}
					</h4>
					<h4 className='modal-title'>
						<span className="card-modal-text">Space name: </span>
						{props.currentCard.space_name}
					</h4>
					<h4 className='modal-title'>
						<span className="card-modal-text">User name: </span>
						{props.currentCard.user_name}
					</h4>

					<h4 className='modal-title'>
						<span className="card-modal-text">Comment: </span>
						<span dangerouslySetInnerHTML={{__html: props.currentCard.comment}}/>
					</h4>
					<h4 className='modal-title'>
						<span className="card-modal-text">Size: </span>
						{ Math.round(props.currentCard.size * 1e-6 * 100) / 100 } Mb
					</h4>
					<h4 className='modal-title'>
						<span className="card-modal-text">Download: </span>
						<a href={ props.currentCard.direct_link }>{ props.currentCard.direct_link }</a>
					</h4>
				</div>
				<div className='modal-footer'>
					<button className='modal-button' onClick={props.onClose}>
						Close
					</button>
				</div>
			</div>
		</div>
	)
}

export default Modal
